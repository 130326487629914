import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { initializeApp } from "firebase/app";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

const firebaseConfig = {
  apiKey: "AIzaSyA4WZ1TPlczZAu6LBEUtZ38RxP6IAxH8oY",
  authDomain: "htien-lounas-dev.firebaseapp.com",
  projectId: "htien-lounas-dev",
  storageBucket: "htien-lounas-dev.appspot.com",
  messagingSenderId: "451780213308",
  appId: "1:451780213308:web:69abd081a8be4aded0e139",
  measurementId: "G-3TRX5RT3EF",
};

export const app = initializeApp(firebaseConfig);
